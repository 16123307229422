import React, { createContext, useState } from "react";

const WebSearchContext = createContext(null);

const WebSearchProvider = ({ children }) => {
  const [question, setQuestion] = useState("");
  const [searching, setSearching] = useState(false);
  const [matches, setMatches] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [chatProvider, setChatProvider] = useState("cohere");
  const [model, setModel] = useState("command-r");
  const [searchUrl, setSearchUrl] = useState("");

  const resetSearchData = () => {
    setQuestion("");
    setSearching(false);
    setMatches([]);
    setAnswer([]);
    setChatProvider("cohere");
    setModel("command-r");
    setSearchUrl("");
  };

  return (
    <WebSearchContext.Provider
      value={{
        question,
        setQuestion,
        searching,
        setSearching,
        matches,
        setMatches,
        answer,
        setAnswer,
        chatProvider,
        setChatProvider,
        model,
        setModel,
        searchUrl,
        setSearchUrl,
      }}
    >
      {children}
    </WebSearchContext.Provider>
  );
};

export { WebSearchContext, WebSearchProvider };
