import { fetchWithJWT } from "../utils/fetchWithJWT";
import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  // sendPasswordResetEmail,
  // sendEmailVerification,
  // updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

const createUser = async () => {
  fetchWithJWT("/api/users/create_user", await auth.currentUser.getIdToken(), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  await createUserWithEmailAndPassword(auth, email, password);
  createUser();
};

export const doSignInWithEmailAndPassword = (email, password) =>
  new Promise(async (resolve) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      resolve();
    } catch (err) {
      resolve(err.message);
    }
  });

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
  createUser();
};

export const doSignOut = async () => {
  auth.signOut();
};

// export const doPasswordReset = (email) => {
//   return sendPasswordResetEmail(auth, email);
// };

// export const doPasswordChange = (password) => {
//   return updatePassword(auth.currentUser, password);
// };

// export const doSendEmailVerification = () => {
//   return sendEmailVerification(auth.currentUser, {
//     url: `${window.location.origin}/home`,
//   });
// };
