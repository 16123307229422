import { useState } from "react";
import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Button,
  Divider,
  // Checkbox,
  Anchor,
  Stack,
  Group,
  Box,
} from "@mantine/core";
import { GoogleButton } from "./GoogleButton";
import {
  doCreateUserWithEmailAndPassword,
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
} from "../firebase/auth";
import { useEffect } from "react";

export function Authentication(props) {
  const [type, toggle] = useToggle(["login", "register"]);
  const [invalidCreds, setInvalidCreds] = useState(false);

  useEffect(() => {
    toggle(props.initialToggle);
  }, [props.initialToggle, toggle]);

  useEffect(() => {
    setInvalidCreds(false);
  }, [type]);

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      // terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Paper
        radius="md"
        p="lg"
        withBorder
        style={{ width: 350, height: "auto" }}
        {...props}
      >
        <Text size="md" fw={500} style={{ paddingBottom: "15px" }}>
          Welcome to Zairo, {type} with
        </Text>

        <form
          onSubmit={form.onSubmit(async () => {
            setInvalidCreds(false);
            if (type === "login") {
              const response = await doSignInWithEmailAndPassword(
                form.values.email,
                form.values.password
              );
              !!response && setInvalidCreds(true);
            } else {
              await doCreateUserWithEmailAndPassword(
                form.values.email,
                form.values.password
              );
            }
          })}
        >
          <Stack>
            {type === "register" && (
              <TextInput
                description="Name"
                placeholder="Your name"
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                radius="md"
                size="sm"
              />
            )}

            <TextInput
              required
              description="Email"
              placeholder="example@zairo.ai"
              value={form.values.email}
              onChange={(event) =>
                form.setFieldValue("email", event.currentTarget.value)
              }
              error={form.errors.email && "Invalid email"}
              radius="md"
              size="sm"
            />

            <PasswordInput
              required
              description="Password"
              placeholder="Your password"
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={
                form.errors.password &&
                "Password should include at least 6 characters"
              }
              radius="md"
              size="sm"
            />
          </Stack>
          {invalidCreds && (
            <Box w="100%" ta="center" mt="lg">
              <Text c={"red"} size="sm">
                Invalid username and/or password
              </Text>
            </Box>
          )}
          <Group justify="space-between" mt="lg">
            <Anchor
              component="button"
              type="button"
              c="dimmed"
              onClick={() => {
                const newUrl = `/${type === "register" ? "login" : "register"}`;
                window.history.pushState(null, null, newUrl);
                toggle();
              }}
              size="xs"
            >
              {type === "register"
                ? "Already have an account? Login"
                : "Don't have an account? Register"}
            </Anchor>
            <Button type="submit" radius="xl" size="sm">
              {upperFirst(type)}
            </Button>
          </Group>
        </form>

        <Divider label="OR" labelPosition="center" my="md" />

        <Group
          grow
          mb="sm"
          mt="sm"
          onClick={async () => {
            doSignInWithGoogle().catch((error) => {
              console.error(error.message);
            });
          }}
        >
          <GoogleButton radius="xl">Continue with Google</GoogleButton>
        </Group>
      </Paper>
    </div>
  );
}
