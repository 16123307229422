import React, { createContext, useState } from "react";

const FolderSearchContext = createContext(null);

const FolderSearchProvider = ({ children }) => {
  const [folderIds, setFolderIds] = useState([]);
  const [question, setQuestion] = useState("");
  const [searching, setSearching] = useState(false);
  const [matches, setMatches] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [chatProvider, setChatProvider] = useState("cohere");
  const [model, setModel] = useState("command-r");

  const resetFolderSearchContext = () => {
    setFolderIds([]);
    setQuestion("");
    setSearching(false);
    setMatches([]);
    setAnswer([]);
    setChatProvider("cohere");
    setModel("command-r");
  };

  return (
    <FolderSearchContext.Provider
      value={{
        folderIds,
        setFolderIds,
        question,
        setQuestion,
        searching,
        setSearching,
        matches,
        setMatches,
        answer,
        setAnswer,
        chatProvider,
        setChatProvider,
        model,
        setModel,
        resetFolderSearchContext,
      }}
    >
      {children}
    </FolderSearchContext.Provider>
  );
};

export { FolderSearchContext, FolderSearchProvider };
