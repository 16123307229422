import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const AuthRoutes = () => {
  const { userLoggedIn } = useAuth();

  return !userLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default AuthRoutes;
