import {
  Paper,
  TextInput,
  Button,
  Container,
  Loader,
  Text,
  SegmentedControl,
  Box,
  MultiSelect,
} from "@mantine/core";
import SearchList from "./SearchList";
import React, { useContext, useState } from "react";
import { IconAlertCircle, IconFolder, IconWorldWww } from "@tabler/icons-react";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";
import { FolderSearchContext } from "../contexts/folder/FolderSearchContext";
import { MainContext } from "../contexts/MainContext";
import { fetchWithJWT } from "../utils/fetchWithJWT";
import { useAuth } from "../contexts/AuthContext";
import { SpaceContext } from "../contexts/folder/SpaceContext";
import { notifications } from "@mantine/notifications";

const FolderSearch = () => {
  const { token } = useAuth();
  const { searchType, setSearchType, setDataType } = useContext(MainContext);
  const {
    folders,
    files,
    webpages,
    setWebpagePreviewTab,
    setPreviewData,
    resetPreviewDataContext,
  } = useContext(FolderDataContext);
  const {
    folderIds,
    setFolderIds,
    question,
    setQuestion,
    searching,
    setSearching,
    matches,
    setMatches,
    answer,
    setAnswer,
    chatProvider,
    setChatProvider,
    model,
    setModel,
  } = useContext(FolderSearchContext);
  const { selectedSpace } = useContext(SpaceContext);

  const [hoveredSentenceIndex, setHoveredSentenceIndex] = useState(null);
  const [hoveredSupIndex, setHoveredSupIndex] = useState(null);

  const search = async () => {
    setSearching(true);

    const requestBody = {
      spaceId: selectedSpace.id,
      question: question,
      chatProvider: chatProvider,
      model: model,
      folderIds: folderIds,
    };

    fetchWithJWT("/api/search/folder", token, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (res.matches.length === 0) {
          alert(
            "Embeddings are still uploading. Please try again in a few seconds."
          );
        } else {
          setMatches(
            res.matches.map((match) => {
              return {
                similarity: match.score,
                text: match.values[0],
                chunkNum: parseInt(match.chunkNum),
                datumId: match.datumId,
                dataType: match.dataType,
                matchNum: match.matchNum,
              };
            })
          );

          setAnswer(res.answer);
        }
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const displayAnswer = () => {
    const handleMatchClick = (datumId, dataType, chunkNum, citedText) => {
      resetPreviewDataContext();

      if (dataType === "webpage") {
        setWebpagePreviewTab("Parsed Text");
      }

      setDataType(dataType);
      setPreviewData((prevState) => ({
        ...prevState,
        selectedDatum:
          dataType === "file"
            ? files.find((file) => file.id === datumId)
            : webpages.find((webpage) => webpage.id === datumId),
        selectedChunkNum: chunkNum,
        citedText: citedText,
      }));
    };

    return (
      <Text
        style={{
          margin: "10px 25px 25px 25px",
          whiteSpace: "pre-line",
        }}
      >
        {answer.map((item, answerIdx) => (
          <React.Fragment key={answerIdx}>
            {item.contextReference ? (
              <u
                style={{
                  textDecoration:
                    hoveredSentenceIndex === answerIdx
                      ? "underline dotted"
                      : "none",
                  cursor: "default",
                }}
                onMouseEnter={() => setHoveredSentenceIndex(answerIdx)}
                onMouseLeave={() => setHoveredSentenceIndex(null)}
              >
                {item.sentence}
                {item.contextReference.matchNums
                  .slice(0, 3)
                  .map((matchNum, matchIdx) => {
                    const supIdx =
                      answerIdx.toString() + "-" + matchIdx.toString();

                    return (
                      <sup
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "15px",
                          height: "15px",
                          borderRadius: "50%",
                          backgroundColor:
                            hoveredSupIndex === supIdx ? "#238BE6" : "#f0f0f0",
                          color: hoveredSupIndex === supIdx ? "white" : "black",
                          fontSize: "50%",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        key={supIdx}
                        onMouseEnter={() => setHoveredSupIndex(supIdx)}
                        onMouseLeave={() => setHoveredSupIndex(null)}
                        onClick={() => {
                          handleMatchClick(
                            item.contextReference.datumIds?.[matchIdx],
                            item.contextReference.dataTypes?.[matchIdx],
                            item.contextReference.chunkNums?.[matchIdx],
                            item.sentence
                          );
                        }}
                      >
                        {matchNum}
                      </sup>
                    );
                  })}
              </u>
            ) : (
              <React.Fragment>{item.sentence} </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </Text>
    );
  };

  return (
    <Paper padding="md" shadow="xl" radius="md" style={{ height: "90vh" }}>
      <Container
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <SegmentedControl
          style={{
            marginLeft: "20px",
          }}
          radius="xl"
          data={[
            {
              value: "folder",
              label: (
                <IconFolder
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              ),
            },
            {
              value: "web",
              label: (
                <IconWorldWww
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              ),
            },
          ]}
          value={searchType}
          onChange={(value) => {
            setSearchType(value);
            if (value === "web") {
              notifications.show({
                title: "Web Search is experimental and still in development",
                icon: <IconAlertCircle size={24} />,
                color: "yellow",
                styles: (theme) => ({
                  root: {
                    backgroundColor: theme.colors.yellow[0],
                    borderColor: theme.colors.yellow[6],
                  },
                  title: {
                    color: theme.colors.yellow[7],
                  },
                }),
              });
            }
          }}
        />
        <TextInput
          placeholder="Enter a search query"
          value={question}
          style={{ flexGrow: 1 }}
          radius="md"
          size="sm"
          disabled={searching || !selectedSpace}
          onChange={(event) => {
            setQuestion(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter" && !searching && question !== "") {
              search();
            }
          }}
        />
        <MultiSelect // TODO: Use https://mantine.dev/combobox/?e=MaxDisplayedItems to limit height
          placeholder="Select Folder(s)"
          style={{
            width: "150px",
          }}
          size="sm"
          radius="md"
          data={folders.map((folder) => ({
            label: folder.name,
            value: folder.id,
          }))}
          onChange={(value) => {
            setFolderIds(value);
          }}
          disabled={searching || !selectedSpace}
        />

        <Button
          style={{
            marginRight: "20px",
          }}
          variant="filled"
          radius="lg"
          size="sm"
          color="black"
          disabled={searching || question === "" || !selectedSpace}
          onClick={search}
        >
          {searching ? <Loader size="sm" color="black" /> : "Search"}
        </Button>
      </Container>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <SegmentedControl
          style={{ margin: "20px 0px 0px 35px" }}
          radius="xl"
          data={[
            { value: "openai", label: "OpenAI" },
            { value: "cohere", label: "Cohere" },
          ]}
          value={chatProvider}
          onChange={(value) => {
            setChatProvider(value);

            if (value === "openai") {
              setModel("gpt-3.5-turbo");
            } else {
              setModel("command-r");
            }
          }}
          disabled={searching || !selectedSpace}
        />
        {chatProvider === "openai" ? (
          <SegmentedControl
            key="openai"
            style={{ marginTop: "20px" }}
            radius="xl"
            data={[
              { value: "gpt-3.5-turbo", label: "GPT-3.5 Turbo" },
              { value: "gpt-4-turbo", label: "GPT-4 Turbo" },
              { value: "gpt-4o", label: "GPT-4o" },
            ]}
            value={model}
            onChange={(value) => {
              setModel(value);
            }}
            disabled={searching || !selectedSpace}
          />
        ) : (
          <SegmentedControl
            key="cohere"
            style={{ marginTop: "20px" }}
            radius="xl"
            data={[
              { value: "command-r", label: "Command R" },
              { value: "command-r-plus", label: "Command R+" },
            ]}
            value={model}
            onChange={(value) => {
              setModel(value);
            }}
            disabled={searching || !selectedSpace}
          />
        )}
      </Box>

      <Container
        style={{
          margin: "10px",
          height: "75vh",
          overflow: "auto",
        }}
      >
        {displayAnswer()}
        <SearchList matches={matches} />
      </Container>
    </Paper>
  );
};

export default FolderSearch;
