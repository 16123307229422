import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./Home";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import { Authentication } from "./authentication/Authentication";
import AuthRoutes from "./utils/AuthRoutes";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route element={<AuthRoutes />}>
            <Route
              path="/login"
              element={<Authentication initialToggle={"login"} />}
            />
            <Route
              path="/register"
              element={<Authentication initialToggle={"register"} />}
            />
          </Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
