import { Box, Paper, ScrollArea, Text } from "@mantine/core";
import MyPdfViewer from "./MyPdfViewer";
import { IconFiles } from "@tabler/icons-react";
import { useContext } from "react";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";

const FilePreview = () => {
  const { previewData } = useContext(FolderDataContext);

  return (
    <Paper
      padding="md"
      shadow="md"
      radius="md"
      style={{
        minHeight: "100%",
        ...(previewData.selectedDatum
          ? {}
          : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }),
      }}
    >
      {!previewData.selectedDatum ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <IconFiles size={110} color="lightgrey" />
          <Text
            size="45px"
            style={{ margin: "20px 0px 20px 0px", color: "lightgrey" }}
          >
            No File Selected
          </Text>
        </Box>
      ) : (
        <ScrollArea
          style={{
            height: "90vh",
            padding: "20px",
          }}
          scrollbarSize={4}
        >
          <MyPdfViewer />
        </ScrollArea>
      )}
    </Paper>
  );
};

export default FilePreview;
