import { Box, Button, Modal, NavLink, Select, TextInput } from "@mantine/core";
import { IconCubePlus, IconUserPlus } from "@tabler/icons-react";
import { fetchWithJWT } from "../utils/fetchWithJWT";
import { useAuth } from "../contexts/AuthContext";
import { useContext, useEffect, useState, useRef } from "react";
import { SpaceContext } from "../contexts/folder/SpaceContext";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";
import { FolderSearchContext } from "../contexts/folder/FolderSearchContext";
import { notifications } from "@mantine/notifications";

const SpaceList = () => {
  const { token } = useAuth();
  const { spaces, setSpaces, selectedSpace, setSelectedSpace } =
    useContext(SpaceContext);
  const { resetFolderDataContext } = useContext(FolderDataContext);
  const { resetFolderSearchContext } = useContext(FolderSearchContext);

  const [spaceModalOpen, setSpaceModalOpen] = useState(false);
  const [spaceName, setSpaceName] = useState("");

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareEmail, setShareEmail] = useState("");

  const spaceRef = useRef(null);
  const shareRef = useRef(null);

  useEffect(() => {
    if (spaceModalOpen) {
      const timer = setTimeout(() => {
        if (spaceRef.current) {
          spaceRef.current.focus();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [spaceModalOpen]);

  useEffect(() => {
    if (shareModalOpen) {
      const timer = setTimeout(() => {
        if (shareRef.current) {
          shareRef.current.focus();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [shareModalOpen]);

  useEffect(() => {
    if (spaces.length > 0 && selectedSpace === null) {
      setSelectedSpace(spaces[0]);
    }
  }, [spaces, selectedSpace, setSelectedSpace]);

  useEffect(() => {
    fetchWithJWT("/api/spaces/", token, {
      headers: { "Content-Type": "application/json" },
    })
      .then((data) => {
        setSpaces(data.spaces);
      })
      .catch((error) => {
        console.error("Error fetching spaces:", error);
      });
  }, [setSpaces, token]);

  const onCreateSpace = () => {
    fetchWithJWT("/api/spaces/create", token, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: spaceName,
      }),
    })
      .then((response) => {
        const newSpace = response["space"];
        setSpaces([...spaces, newSpace]);
        setSpaceModalOpen(false);
        notifications.show({
          title: "Space Successfully Created",
          color: "green",
        });
        setSpaceName("");
      })
      .catch((error) => {
        console.error("Error creating space:", error);
      });
  };

  const onShareFiles = () => {
    fetchWithJWT("/api/spaces/share", token, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: shareEmail,
        spaceId: selectedSpace.id,
      }),
    })
      .then(() => {
        setShareModalOpen(false);
        setShareEmail("");
      })
      .catch((error) => {
        console.error("Error adding user to space:", error);
      });
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {spaces.length === 0 ? (
          <NavLink
            label="Create a space"
            style={{
              border: "1px dashed grey",
              borderRadius: "5px",
            }}
            onClick={() => {
              setSpaceModalOpen(true);
            }}
          />
        ) : (
          <Select
            placeholder="Select Space"
            data={spaces.map((space) => ({
              value: space.id,
              label: space.name,
            }))}
            onChange={(value) => {
              const space = spaces.find((space) => space.id === value);

              if (space) {
                setSelectedSpace(space);
              } else {
                setSelectedSpace(null);
              }

              resetFolderDataContext();
              resetFolderSearchContext();
            }}
            value={selectedSpace?.id}
            allowDeselect={false}
          />
        )}
        <IconCubePlus
          style={{
            cursor: "pointer",
            flexShrink: 0,
          }}
          onClick={() => {
            setSpaceModalOpen(true);
          }}
        />
        <IconUserPlus
          style={{
            cursor: selectedSpace === null ? "not-allowed" : "pointer",
            color: selectedSpace === null ? "grey" : "inherit",
            opacity: selectedSpace === null ? 0.5 : 1,
            flexShrink: 0,
          }}
          onClick={() => {
            if (selectedSpace !== null) {
              setShareModalOpen(true);
            }
          }}
        />
      </Box>

      <Modal
        opened={spaceModalOpen}
        onClose={() => {
          setSpaceModalOpen(false);
        }}
        title="Create Space"
        centered
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextInput
            ref={spaceRef}
            style={{
              flexGrow: 1,
            }}
            autoFocus={true}
            placeholder="Enter space name"
            value={spaceName}
            required
            onChange={(e) => setSpaceName(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && spaceName !== "") {
                onCreateSpace();
              }
            }}
          />
          <Button
            type="submit"
            disabled={spaceName === ""}
            onClick={onCreateSpace}
          >
            Create
          </Button>
        </Box>
      </Modal>

      <Modal
        opened={shareModalOpen}
        onClose={() => {
          setShareModalOpen(false);
        }}
        title={`Add User to ${selectedSpace?.name}`}
        centered
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextInput
            ref={shareRef}
            style={{
              flexGrow: 1,
            }}
            placeholder="Enter user email"
            value={shareEmail}
            required
            onChange={(e) => setShareEmail(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && shareEmail !== "") {
                onShareFiles();
              }
            }}
          />
          <Button
            type="submit"
            disabled={shareEmail === ""}
            onClick={onShareFiles}
          >
            Share
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default SpaceList;
