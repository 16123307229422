import { useQuery } from "react-query";
import { fetchWithJWT } from "../utils/fetchWithJWT";
import { useAuth } from "../contexts/AuthContext";

export function useSnapshot(url, id) {
  const { token } = useAuth();

  const fetchSnapshot = async () => {
    const response = await fetchWithJWT("/api/data/web_snapshot", token, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url,
        outputPath: id,
      }),
    });

    return response.s3_url;
  };

  return useQuery(["snapshot", url, id], fetchSnapshot, {
    enabled: !!url,
  });
}
