import { useContext } from "react";
import { Box, NavLink } from "@mantine/core";
import { IconWorld } from "@tabler/icons-react";
import { WebDataContext } from "../contexts/web/WebDataContext";
import { MainContext } from "../contexts/MainContext";
const WebDataList = () => {
  const { webpages, previewData, setPreviewData, resetPreviewDataContext } =
    useContext(WebDataContext);
  const { setDataType } = useContext(MainContext);

  return (
    <Box>
      {webpages.length === 0 ? (
        <NavLink
          label="Search the web to see results"
          style={{
            border: "1px dashed grey",
            borderRadius: "5px",
          }}
        />
      ) : (
        webpages.map((webpage) => {
          return (
            <NavLink
              label={webpage?.url}
              style={{
                whiteSpace: "nowrap",
              }}
              leftSection={<IconWorld />}
              onClick={() => {
                resetPreviewDataContext();

                setPreviewData((prevState) => ({
                  ...prevState,
                  selectedDatum: webpage,
                }));

                setDataType("webpage");
              }}
              active={previewData.selectedDatum?.id === webpage.id}
            />
          );
        })
      )}
    </Box>
  );
};
export default WebDataList;
