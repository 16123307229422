import { Container, NavLink, Text, Divider, Box } from "@mantine/core";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";
import { useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { WebDataContext } from "../contexts/web/WebDataContext";

const SearchList = ({ matches }) => {
  const FolderData = useContext(FolderDataContext);
  const WebData = useContext(WebDataContext);

  const { searchType, setDataType } = useContext(MainContext);
  const {
    files,
    webpages,
    setWebpagePreviewTab,
    setPreviewData,
    resetPreviewDataContext,
  } = searchType === "folder" ? FolderData : WebData;

  return (
    <Container>
      <Container
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
          margin: "10px 0px",
        }}
      >
        <Text size="25px" fw={700}>
          Top Matches
        </Text>
        <Text size="md">Similarity Score</Text>
      </Container>
      <Divider style={{ margin: "10px 0px" }} />
      {matches.map((match, idx) => {
        const dataType = match.dataType;
        const datum =
          dataType === "file"
            ? files.find((file) => file.id === match.datumId)
            : webpages.find((webpage) => webpage.id === match.datumId);

        return (
          <NavLink
            label={
              <Box>
                <Text
                  style={{
                    fontSize: "10px",
                    color: "#238BE6",
                  }}
                  lineClamp={1}
                >
                  {" "}
                  {dataType === "file" ? datum?.name : datum?.url}
                </Text>
                <Text lineClamp={2} size="sm">
                  {match.text}
                </Text>
              </Box>
            }
            onClick={() => {
              resetPreviewDataContext();

              setPreviewData((prevState) => ({
                ...prevState,
                selectedDatum: datum,
                selectedChunkNum: match.chunkNum,
              }));

              if (dataType === "webpage") {
                setWebpagePreviewTab("Parsed Text");
              }

              setDataType(dataType);
            }}
            style={{ height: "80px" }}
            leftSection={
              <Text
                fw={700}
                style={{
                  color: "white",
                  backgroundColor: "#238BE6",
                  border: "1px solid #C4C4C4",
                  width: "32px",
                  height: "32px",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size="24px"
              >
                {idx + 1}
              </Text>
            }
            rightSection={
              <Text
                fw={700}
                style={{
                  color: "#238BE6",
                  marginLeft: "10px",
                }}
              >
                {Math.round(match.similarity * 10000) / 100 + "%"}
              </Text>
            }
          />
        );
      })}
    </Container>
  );
};
export default SearchList;
