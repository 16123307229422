import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import { FolderDataProvider } from "./contexts/folder/FolderDataContext";
import { FolderSearchProvider } from "./contexts/folder/FolderSearchContext";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { MainProvider } from "./contexts/MainContext";
import { AuthProvider } from "./contexts/AuthContext";
import { WebSearchProvider } from "./contexts/web/WebSearchContext";
import { WebDataProvider } from "./contexts/web/WebDataContext";
import { SpaceProvider } from "./contexts/folder/SpaceContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <MainProvider>
        <SpaceProvider>
          <FolderDataProvider>
            <FolderSearchProvider>
              <WebDataProvider>
                <WebSearchProvider>
                  <MantineProvider /* defaultColorScheme="dark" */>
                    <Notifications />
                    <App />
                  </MantineProvider>
                </WebSearchProvider>
              </WebDataProvider>
            </FolderSearchProvider>
          </FolderDataProvider>
        </SpaceProvider>
      </MainProvider>
    </AuthProvider>
  </React.StrictMode>
);
