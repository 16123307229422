// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcUtdPm3dimDMAEYkcfqejzv5CpPQh9jc",
  authDomain: "zairo-ai.firebaseapp.com",
  projectId: "zairo-ai",
  storageBucket: "zairo-ai.appspot.com",
  messagingSenderId: "531758493060",
  appId: "1:531758493060:web:a771942a13bba1f7b8c1d1",
  measurementId: "G-XE185VRMQT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth };
