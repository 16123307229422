import React, { createContext, useState } from "react";

const SpaceContext = createContext(null);

const SpaceProvider = ({ children }) => {
  const [spaces, setSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);

  return (
    <SpaceContext.Provider
      value={{
        spaces,
        setSpaces,
        selectedSpace,
        setSelectedSpace,
      }}
    >
      {children}
    </SpaceContext.Provider>
  );
};

export { SpaceContext, SpaceProvider };
