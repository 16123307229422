import { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  FileInput,
  Modal,
  NavLink,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { PDF_MIME_TYPE } from "@mantine/dropzone";
import {
  IconFile,
  IconFileUpload,
  IconFolder,
  IconFolderPlus,
  IconWorld,
  IconWorldDownload,
} from "@tabler/icons-react";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";
import { notifications } from "@mantine/notifications";
import { MainContext } from "../contexts/MainContext";
import { fetchWithJWT } from "../utils/fetchWithJWT";
import { useAuth } from "../contexts/AuthContext";
import SpaceList from "./SpaceList";
import { SpaceContext } from "../contexts/folder/SpaceContext";

const DataList = () => {
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [folderIdForFileUpload, setFolderIdForFileUpload] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [webpageModalOpen, setWebpageModalOpen] = useState(false);
  const [folderIdForWebpageDownload, setFolderIdForWebpageDownload] =
    useState(null);
  const [webpageToDownload, setWebpageToDownload] = useState("");
  const [downloadingWebpage, setDownloadingWebpage] = useState(false);

  const uploadFileRef = useRef(0);
  const folderRef = useRef(null);

  useEffect(() => {
    if (folderModalOpen) {
      const timer = setTimeout(() => {
        if (folderRef.current) {
          folderRef.current.focus();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [folderModalOpen]);
  

  const { token } = useAuth();
  const { setDataType } = useContext(MainContext);
  const {
    folders,
    setFolders,
    files,
    setFiles,
    webpages,
    setWebpages,
    setWebpagePreviewTab,
    previewData,
    setPreviewData,
    resetPreviewDataContext,
  } = useContext(FolderDataContext);
  const { selectedSpace } = useContext(SpaceContext);

  useEffect(() => {
    if (!selectedSpace) {
      return;
    }

    fetchWithJWT("/api/data/", token, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        spaceId: selectedSpace.id,
      }),
    })
      .then((data) => {
        setFolders(data.folders);
        setFiles(data.files);
        setWebpages(data.webpages);
      })
      .catch((error) => {
        console.error("Error fetching files:", error);
      });
  }, [setFolders, setFiles, setWebpages, selectedSpace, token]);

  const onUploadFile = () => {
    const id = notifications.show({ message: 'Your file upload request is being processed.', loading: true, autoClose: false });
    filesToUpload.forEach((file) => {
      const formData = new FormData();
      formData.append("spaceId", selectedSpace.id);
      formData.append("folderId", folderIdForFileUpload);
      formData.append("file", file);

      return fetchWithJWT("/api/data/upload_file", token, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          setFiles((prevFiles) => [...prevFiles, response["file"]]);
          notifications.show({
            title: "File Successfully Uploaded",
            color: "green",
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          notifications.show({
            title: "Upload Error",
            message: "The provided file cannot be uploaded. Please try again.",
            color: "red",
          });
        })
        .finally(() => {
          uploadFileRef.current += 1;
          if (uploadFileRef.current === filesToUpload.length) notifications.hide(id);
        });
    });

    setFilesToUpload(null);
    setFolderIdForFileUpload(null);
  };

  const onDownloadWebpage = () => {
    setDownloadingWebpage(true);

    fetchWithJWT("/api/data/download_webpage", token, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        spaceId: selectedSpace.id,
        folderId: folderIdForWebpageDownload,
        url: webpageToDownload,
      }),
    })
      .then((response) => {
        const newWebpage = response["webpage"];
        setWebpages((prevWebpages) => [...prevWebpages, newWebpage]);

        setWebpageModalOpen(false);
        setWebpageToDownload("");
        setFolderIdForWebpageDownload(null);
        setWebpageModalOpen(false);
      })
      .catch((error) => {
        notifications.show({
          title: "Download Error",
          message: "The provided webpage cannot be downloaded.",
          color: "red",
        });
        console.error("Error downloading webpage:", error);
      })
      .finally(() => {
        setDownloadingWebpage(false);
      });
  };

  const onCreateFolder = () => {
    fetchWithJWT("/api/data/create_folder", token, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: folderName,
        spaceId: selectedSpace.id,
      }),
    })
      .then((response) => {
        const newFolder = response["folder"];
        setFolders([...folders, newFolder]);
        setFolderModalOpen(false);
        notifications.show({
          title: "Folder Successfully Created",
          color: "green",
        });
        setFolderName("");
      })
      .catch((error) => {
        console.error("Error creating folder:", error);
      });
  };

  const folderToDataMap = folders.reduce((acc, folder) => {
    acc[folder.id] = {
      files: [],
      webpages: [],
    };
    return acc;
  }, {});

  files.forEach((file) => {
    if (!folderToDataMap[file.folder_id]) {
      folderToDataMap[file.folder_id] = {
        files: [],
        webpages: [],
      };
    }
    folderToDataMap[file.folder_id]["files"].push(file);
  });

  webpages.forEach((webpage) => {
    if (!folderToDataMap[webpage.folder_id]) {
      folderToDataMap[webpage.folder_id] = {
        files: [],
        webpages: [],
      };
    }

    folderToDataMap[webpage.folder_id]["webpages"].push(webpage);
  });

  return (
    <Box>
      <SpaceList />
      <Divider mt="15px" />
      <Box
        style={{
          display: "flex",
          gap: "10px",
          margin: "10px",
          justifyContent: "flex-end",
        }}
      >
        <IconFolderPlus
          style={{
            cursor: selectedSpace === null ? "not-allowed" : "pointer",
            color: selectedSpace === null ? "grey" : "inherit",
            opacity: selectedSpace === null ? 0.5 : 1,
          }}
          onClick={() => {
            if (selectedSpace !== null) {
              setFolderModalOpen(true);
            }
          }}
        />
        <IconFileUpload
          style={{
            cursor: selectedSpace === null || folders.length === 0 ? "not-allowed" : "pointer",
            color: selectedSpace === null || folders.length === 0 ? "grey" : "inherit",
            opacity: selectedSpace === null || folders.length === 0 ? 0.5 : 1,
          }}
          onClick={() => {
            if (selectedSpace !== null && folders.length > 0) {
              setFileModalOpen(true);
            }
          }}
        />
        <IconWorldDownload
          style={{
            cursor: selectedSpace === null || folders.length === 0 ? "not-allowed" : "pointer",
            color: selectedSpace === null || folders.length === 0 ? "grey" : "inherit",
            opacity: selectedSpace === null || folders.length === 0 ? 0.5 : 1,
          }}
          onClick={() => {
            if (selectedSpace !== null && folders.length > 0) {
              setWebpageModalOpen(true);
            }
          }}
        />
      </Box>

      {folders.length === 0 ? (
        <NavLink
          label="Create a folder"
          style={{
            border: "1px dashed grey",
            borderRadius: "5px",
            cursor: selectedSpace === null ? "not-allowed" : "pointer",
            color: selectedSpace === null ? "grey" : "inherit",
            opacity: selectedSpace === null ? 0.5 : 1,
          }}
          onClick={() => {
            if (selectedSpace !== null) {
              setFolderModalOpen(true);
            }
          }}
        />
      ) : (
        Object.entries(folderToDataMap).map(([folderId, data]) => {
          return (
            <NavLink
              label={folders.find((folder) => folder.id === folderId).name}
              style={{
                whiteSpace: "nowrap",
              }}
              leftSection={<IconFolder />}
              defaultOpened
            >
              {data["files"].map((file) => {
                return (
                  <NavLink
                    label={file.name}
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    leftSection={<IconFile />}
                    onClick={() => {
                      resetPreviewDataContext();

                      setPreviewData((prevState) => ({
                        ...prevState,
                        selectedDatum: file,
                      }));

                      setDataType("file");
                    }}
                    active={previewData.selectedDatum?.id === file.id}
                  />
                );
              })}
              {data["webpages"].map((webpage) => {
                return (
                  <NavLink
                    label={webpage.url}
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    leftSection={<IconWorld />}
                    onClick={() => {
                      resetPreviewDataContext();

                      setPreviewData((prevState) => ({
                        ...prevState,
                        selectedDatum: webpage,
                      }));

                      setWebpagePreviewTab("Webpage");
                      setDataType("webpage");
                    }}
                    active={previewData.selectedDatum?.id === webpage.id}
                  />
                );
              })}
            </NavLink>
          );
        })
      )}

      <Modal
        opened={folderModalOpen}
        onClose={() => {
          setFolderName("");
          setFolderModalOpen(false);
        }}
        title="Create Folder"
        centered
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextInput
            ref={folderRef}
            style={{
              flexGrow: 1,
            }}
            placeholder="Enter folder name"
            value={folderName}
            required
            onChange={(e) => setFolderName(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && folderName !== "") {
                onCreateFolder();
              }
            }}
          />
          <Button
            type="submit"
            disabled={folderName === ""}
            onClick={onCreateFolder}
          >
            Create
          </Button>
        </Box>
      </Modal>
      <Modal
        opened={fileModalOpen}
        onClose={() => {
          setFilesToUpload(null);
          setFolderIdForFileUpload(null);
          setFileModalOpen(false);
        }}
        title="Upload File(s)"
        centered
        size="29vw"
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Select
            placeholder="Select folder"
            style={{
              flexShrink: 0,
            }}
            value={folderIdForFileUpload}
            data={folders.map((folder) => ({
              label: folder.name,
              value: folder.id,
            }))}
            onChange={(value) => {
              setFolderIdForFileUpload(value);
            }}
          />
          <FileInput
            style={{
              flexGrow: 1,
              overflow: "hidden",
            }}
            placeholder={
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Text size="sm">Upload file(s)</Text>
                <IconFileUpload />
              </Box>
            }
            required
            accept={PDF_MIME_TYPE}
            onChange={(acceptedFiles) => {
              setFilesToUpload(acceptedFiles);
            }}
            multiple
          />
          <Button
            type="submit"
            style={{
              flexShrink: 0,
            }}
            disabled={
              !folderIdForFileUpload ||
              !filesToUpload ||
              filesToUpload.length === 0
            }
            onClick={() => {
              onUploadFile();
              setFileModalOpen(false);
            }}
          >
            Upload
          </Button>
        </Box>
      </Modal>
      <Modal
        opened={webpageModalOpen}
        onClose={() => {
          setWebpageToDownload(null);
          setWebpageModalOpen(false);
          setFolderIdForWebpageDownload(null);
        }}
        title="Download Webpage"
        centered
        size="30vw"
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Select
            placeholder="Select folder"
            style={{
              flexShrink: 0,
            }}
            value={folderIdForWebpageDownload}
            data={folders.map((folder) => ({
              label: folder.name,
              value: folder.id,
            }))}
            onChange={(value) => {
              setFolderIdForWebpageDownload(value);
            }}
          />
          <TextInput
            style={{
              flexGrow: 1,
              overflow: "hidden",
            }}
            placeholder="Enter webpage URL"
            value={webpageToDownload}
            required
            onChange={(e) => setWebpageToDownload(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && webpageToDownload !== "") {
                onDownloadWebpage();
              }
            }}
          />
          <Button
            type="submit"
            style={{
              flexShrink: 0,
            }}
            disabled={
              !folderIdForWebpageDownload ||
              !webpageToDownload ||
              downloadingWebpage
            }
            onClick={() => {
              onDownloadWebpage();
            }}
          >
            Download
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
export default DataList;
