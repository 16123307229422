import React from "react";
import PropTypes from "prop-types";

const Highlight = ({
  position: { boundingRect, rects },
  startIdx,
  endIdx,
  onClick,
  onMouseOver,
  onMouseOut,
  comment,
  isScrolledTo,
  precedingRectsCount,
}) => (
  <div>
    <div>
      {rects.map((rect, index) => {
        const globalIndex = precedingRectsCount + index;
        const isWithinSuchunkRange =
          startIdx != null &&
          endIdx != null &&
          startIdx <= globalIndex &&
          globalIndex <= endIdx;

        return (
          <div
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            key={index}
            style={{
              ...rect,
              cursor: "pointer",
              position: "absolute",
              background: isWithinSuchunkRange
                ? "rgba(165, 216, 255, 1)"
                : "rgba(165, 216, 255, 0.25)",
            }}
          />
        );
      })}
    </div>
  </div>
);

Highlight.propTypes = {
  position: PropTypes.shape({
    boundingRect: PropTypes.object.isRequired,
    rects: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  startIdx: PropTypes.number,
  endIdx: PropTypes.number,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  comment: PropTypes.shape({
    emoji: PropTypes.string,
    text: PropTypes.string,
  }),
  isScrolledTo: PropTypes.bool.isRequired,
};

export default Highlight;
