import React, { createContext, useState } from "react";

const FolderDataContext = createContext(null);

const FolderDataProvider = ({ children }) => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [webpages, setWebpages] = useState([]);
  const [webpagePreviewTab, setWebpagePreviewTab] = useState("Webpage");
  const [urlSnapshot, setUrlSnapshot] = useState(null);
  const [previewData, setPreviewData] = useState({
    selectedDatum: null,
    selectedChunkNum: null,
    webpageText: null,
    chunkText: null,
    subchunkText: null,
    citedText: null,
  });

  const resetFolderDataContext = () => {
    setFolders([]);
    setFiles([]);
    setWebpages([]);
    setWebpagePreviewTab("Webpage");
    setUrlSnapshot(null);
    resetPreviewDataContext();
  };

  const resetPreviewDataContext = () => {
    setPreviewData({
      selectedDatum: null,
      selectedChunkNum: null,
      webpageText: null,
      chunkText: null,
      subchunkText: null,
      citedText: null,
      subchunkStartIdx: null,
      subchunkEndIdx: null,
    });
  };

  return (
    <FolderDataContext.Provider
      value={{
        folders,
        setFolders,
        files,
        setFiles,
        webpages,
        setWebpages,
        webpagePreviewTab,
        setWebpagePreviewTab,
        urlSnapshot,
        setUrlSnapshot,
        previewData,
        setPreviewData,
        resetFolderDataContext,
        resetPreviewDataContext,
      }}
    >
      {children}
    </FolderDataContext.Provider>
  );
};

export { FolderDataContext, FolderDataProvider };
