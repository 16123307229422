import React, { createContext, useState } from "react";

const MainContext = createContext(null);

const MainProvider = ({ children }) => {
  const [dataType, setDataType] = useState("file");
  const [searchType, setSearchType] = useState("folder");

  return (
    <MainContext.Provider
      value={{
        dataType,
        setDataType,
        searchType,
        setSearchType,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };
