import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { PdfLoader, PdfHighlighter } from "react-pdf-highlighter";
import Highlight from "./Highlight";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";
import { Loader } from "@mantine/core";
import { fetchWithJWT } from "../utils/fetchWithJWT";
import { useAuth } from "../contexts/AuthContext";

const MyPdfViewer = () => {
  const { token } = useAuth();
  const { previewData, setPreviewData } = useContext(FolderDataContext);

  const scrollRef = useRef(null);
  const [chunkHighlights, setChunkHighlights] = useState(null);
  const [highlightedFileId, setHighlightedFileId] = useState(null);
  const [fetchingSubchunk, setFetchingSubchunk] = useState(false);

  const updateScrollRef = useCallback(() => {
    if (!chunkHighlights) return;

    // TODO: fix bug where some highlights aren't shown when a chunk spans multiple pages
    const copyChunkHighlights = { ...chunkHighlights[0] };
    copyChunkHighlights.position.boundingRect.y1 = 0;

    scrollRef.current[1](copyChunkHighlights);
  }, [chunkHighlights, scrollRef]);

  useEffect(() => {
    const fetchChunkHighlights = async () => {
      fetchWithJWT("/api/data/chunk_highlights", token, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileId: previewData.selectedDatum.id,
          chunkNum: previewData.selectedChunkNum,
        }),
      })
        .then((data) => {
          setChunkHighlights(data.chunk_highlights);
          setHighlightedFileId(previewData.selectedDatum.id);
        })
        .catch((error) => {
          console.error("Error fetching chunk highlights:", error);
        });
    };

    if (
      previewData.selectedDatum !== null &&
      previewData.selectedChunkNum !== null
    )
      fetchChunkHighlights();
  }, [previewData]);

  useEffect(() => {
    if (scrollRef.current?.[0] === previewData.selectedDatum?.id) {
      updateScrollRef();
    }
  }, [chunkHighlights, previewData.selectedDatum?.id, updateScrollRef]);

  useEffect(() => {
    const fetchSubchunkText = async () => {
      setFetchingSubchunk(true);

      const requestBody = {
        datumId: previewData.selectedDatum.id,
        dataType: "file",
        chunkNum: previewData.selectedChunkNum,
        citedText: previewData.citedText,
      };

      fetchWithJWT("/api/data/refined_subchunk", token, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }).then((res) => {
        setPreviewData((prevState) => ({
          ...prevState,
          subchunkStartIdx: res.startIdx,
          subchunkEndIdx: res.endIdx,
        }));
        setFetchingSubchunk(false);
      });
    };

    if (
      previewData.subchunkStartIdx !== null ||
      previewData.subchunkEndIdx !== null
    ) {
      return;
    }

    if (
      previewData.datumId !== null &&
      previewData.chunkNum !== null &&
      previewData.citedText !== null
    ) {
      fetchSubchunkText();
    }
  }, [previewData, setPreviewData]);

  function precedingRectsCount(highlight) {
    let precedingRectsCount = 0;

    for (let i = 0; i < chunkHighlights.length; i++) {
      if (
        chunkHighlights[i].position.pageNumber === highlight.position.pageNumber
      ) {
        break;
      }
      precedingRectsCount += chunkHighlights[i].position.rects.length;
    }

    return precedingRectsCount;
  }

  return fetchingSubchunk ? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader type="dots" color="black" />
    </div>
  ) : (
    <PdfLoader url={previewData.selectedDatum?.url}>
      {(pdfDocument) => (
        <PdfHighlighter
          pdfDocument={pdfDocument}
          enableAreaSelection={(event) => event.altKey}
          scrollRef={(scrollTo) => {
            scrollRef.current = [previewData.selectedDatum.id, scrollTo];
            updateScrollRef();
          }}
          onSelectionFinished={() => {
            <></>;
          }}
          highlightTransform={(
            highlight,
            index,
            setTip,
            hideTip,
            viewportToScaled,
            screenshot,
            isScrolledTo
          ) => {
            return (
              <Highlight
                isScrolledTo={isScrolledTo}
                position={highlight.position}
                key={index}
                startIdx={previewData?.subchunkStartIdx}
                endIdx={previewData?.subchunkEndIdx}
                precedingRectsCount={precedingRectsCount(highlight)}
              />
            );
          }}
          highlights={
            highlightedFileId === previewData.selectedDatum.id &&
            chunkHighlights
              ? chunkHighlights
              : []
          }
          onScrollChange={() => {}}
          onDocumentReady={() => {}}
        />
      )}
    </PdfLoader>
  );
};

export default MyPdfViewer;
