import React, { createContext, useState } from "react";

const WebDataContext = createContext(null);

const WebDataProvider = ({ children }) => {
  const [webpages, setWebpages] = useState([]);
  const [webpagePreviewTab, setWebpagePreviewTab] = useState("Webpage");
  const [urlSnapshot, setUrlSnapshot] = useState(null);
  const [webpagesChunks, setWebpagesChunks] = useState(null);
  const [previewData, setPreviewData] = useState({
    selectedDatum: null,
    selectedChunkNum: null,
    webpageText: null,
    chunkText: null,
    subchunkText: null,
    citedText: null,
  });

  const resetPreviewDataContext = () => {
    setPreviewData({
      selectedDatum: null,
      selectedChunkNum: null,
      webpageText: null,
      chunkText: null,
      subchunkText: null,
      citedText: null,
      subchunkStartIdx: null,
      subchunkEndIdx: null,
    });
  };

  return (
    <WebDataContext.Provider
      value={{
        webpages,
        setWebpages,
        webpagePreviewTab,
        setWebpagePreviewTab,
        urlSnapshot,
        setUrlSnapshot,
        webpagesChunks,
        setWebpagesChunks,
        previewData,
        setPreviewData,
        resetPreviewDataContext,
      }}
    >
      {children}
    </WebDataContext.Provider>
  );
};

export { WebDataContext, WebDataProvider };
