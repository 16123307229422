import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Highlight,
  Paper,
  ScrollArea,
  Tabs,
  Text,
  Loader,
} from "@mantine/core";
import {
  IconAlignLeft,
  IconWorld,
  IconExternalLink,
} from "@tabler/icons-react";
import { FolderDataContext } from "../contexts/folder/FolderDataContext";
import { MainContext } from "../contexts/MainContext";
import { WebDataContext } from "../contexts/web/WebDataContext";
import { useSnapshot } from "../hooks/useSnapshot";
import { useAuth } from "../contexts/AuthContext";
import { fetchWithJWT } from "../utils/fetchWithJWT";

const WebpagePreview = () => {
  const highlightedRef = useRef(null);

  const [fetchingSubchunk, setFetchingSubchunk] = useState(false);

  const { token } = useAuth();
  const FolderData = useContext(FolderDataContext);
  const WebData = useContext(WebDataContext);

  const { searchType } = useContext(MainContext);
  const {
    webpagePreviewTab,
    setWebpagePreviewTab,
    urlSnapshot,
    setUrlSnapshot,
    webpagesChunks,
    previewData,
    setPreviewData,
  } = searchType === "folder" ? FolderData : WebData;
  const {
    data: currUrlSnapshot,
    error: snapshotError,
    isLoading: snapshotLoading,
  } = useSnapshot(
    previewData.selectedDatum?.url,
    previewData.selectedDatum?.id
  );

  useEffect(() => {
    if (snapshotError || snapshotLoading) {
      setUrlSnapshot(null);
    } else {
      setUrlSnapshot(currUrlSnapshot);
    }
  }, [currUrlSnapshot, snapshotError, snapshotLoading, setUrlSnapshot]);

  useEffect(() => {
    const fetchWebpageText = async () => {
      try {
        const response = await fetchWithJWT("/api/data/webpage_text", token, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chunkNum: previewData.selectedChunkNum,
            webpageId: previewData.selectedDatum.id,
          }),
        });
        const data = await response.json();

        setPreviewData((prevState) => ({
          ...prevState,
          webpageText: data.webpage_text,
          chunkText: data.chunk_text,
        }));
      } catch (error) {
        console.error("Error fetching chunk text:", error);
      }
    };

    if (previewData.webpageText !== null && previewData.chunkText !== null) {
      return;
    }

    if (searchType === "folder" && previewData.selectedDatum !== null) {
      fetchWebpageText();
    } else if (previewData.selectedDatum !== null && webpagesChunks !== null) {
      setPreviewData((prevState) => ({
        ...prevState,
        webpageText: previewData.selectedDatum.webpageText,
        chunkText: webpagesChunks[previewData.selectedChunkNum],
      }));
    }
  }, [previewData, setPreviewData, searchType, webpagesChunks]);

  useEffect(() => {
    const handleScrollToHighlight = () => {
      highlightedRef.current?.scrollIntoView({
        block: "center",
      });
    };

    if (previewData.chunkText !== null) {
      handleScrollToHighlight();
    }
  }, [previewData]);

  useEffect(() => {
    const fetchSubchunkText = async () => {
      setFetchingSubchunk(true);

      const requestBody = {
        datumId: previewData.selectedDatum.id,
        dataType: "webpage",
        chunkNum: previewData.selectedChunkNum,
        citedText: previewData.citedText,
      };

      fetchWithJWT("/api/data/refined_subchunk", token, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }).then((res) => {
        setPreviewData((prevState) => ({
          ...prevState,
          subchunkText: res.subchunk,
        }));
        setFetchingSubchunk(false);
      });
    };

    if (searchType === "web") {
      return;
    }

    if (previewData.subchunkText !== null) {
      return;
    }

    if (
      previewData.datumId !== null &&
      previewData.chunkNum !== null &&
      previewData.citedText !== null
    ) {
      fetchSubchunkText();
    }
  }, [previewData, setPreviewData]);

  const firstWebpageTextSplit = previewData.webpageText?.indexOf(
    previewData.chunkText
  );
  const secondWebpageTextSplit =
    firstWebpageTextSplit + previewData.chunkText?.length;
  const firstChunkTextSplit = previewData.chunkText?.indexOf(
    previewData.subchunkText
  );
  const secondChunkTextSplit =
    firstChunkTextSplit + previewData.subchunkText?.length;

  return (
    <Paper
      padding="md"
      shadow="md"
      radius="md"
      style={{
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!previewData.selectedDatum ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <IconWorld size={110} color="lightgrey" />
          <Text
            size="45px"
            style={{ margin: "20px 0px 20px 0px", color: "lightgrey" }}
          >
            No Webpage Selected
          </Text>
        </Box>
      ) : (
        <Tabs value={webpagePreviewTab}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value="Webpage"
                leftSection={<IconWorld />}
                onClick={() => setWebpagePreviewTab("Webpage")}
              >
                Webpage
              </Tabs.Tab>
              <Tabs.Tab
                value="Parsed Text"
                leftSection={<IconAlignLeft />}
                onClick={() => setWebpagePreviewTab("Parsed Text")}
              >
                Parsed Text
              </Tabs.Tab>
            </Tabs.List>
            <a
              href={previewData.selectedDatum?.url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "black",
                cursor: "pointer",
              }}
            >
              <IconExternalLink cursor="pointer" color="black" />
            </a>
          </div>

          <Tabs.Panel value="Webpage">
            <div
              style={{
                height: "85vh",
                width: "36vw",
                overflow: "auto",
                position: "relative",
                overflowY: "auto",
                scrollbarWidth: "none",
              }}
            >
              {!urlSnapshot ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader type="dots" color="black" />
                </div>
              ) : (
                <img
                  src={urlSnapshot}
                  draggable="false"
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    border: "none",
                  }}
                  alt="Webpage Snapshot"
                />
              )}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="Parsed Text">
            <ScrollArea
              style={{
                height: "85vh",
                width: "36vw",
              }}
              scrollbarSize={4}
            >
              {!previewData.subchunkText && fetchingSubchunk ? (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader type="dots" color="black" />
                </div>
              ) : (
                <Box
                  style={{
                    overflowX: "hidden",
                    padding: "0 20px",
                  }}
                >
                  <span style={{ display: "inline" }}>
                    {previewData.webpageText?.slice(0, firstWebpageTextSplit)}
                  </span>
                  {previewData.subchunkText !== null ? (
                    <>
                      <Highlight
                        ref={highlightedRef}
                        highlight={previewData.chunkText?.slice(
                          0,
                          firstChunkTextSplit
                        )}
                        style={{ display: "inline" }}
                        color="rgba(165, 216, 255, 0.25)"
                      >
                        {previewData.chunkText?.slice(0, firstChunkTextSplit)}
                      </Highlight>

                      <Highlight
                        ref={highlightedRef}
                        highlight={previewData.chunkText?.slice(
                          firstChunkTextSplit,
                          secondChunkTextSplit
                        )}
                        style={{ display: "inline" }}
                        color="rgba(165, 216, 255, 1)"
                      >
                        {previewData.chunkText?.slice(
                          firstChunkTextSplit,
                          secondChunkTextSplit
                        )}
                      </Highlight>

                      <Highlight
                        ref={highlightedRef}
                        highlight={previewData.chunkText?.slice(
                          secondChunkTextSplit
                        )}
                        style={{ display: "inline" }}
                        color="rgba(165, 216, 255, 0.35)"
                      >
                        {previewData.chunkText?.slice(secondChunkTextSplit)}
                      </Highlight>
                    </>
                  ) : (
                    <Highlight
                      ref={highlightedRef}
                      highlight={previewData.chunkText}
                      style={{ display: "inline" }}
                      color="rgba(165, 216, 255, 0.35)"
                    >
                      {previewData.chunkText}
                    </Highlight>
                  )}
                  <span style={{ display: "inline" }}>
                    {previewData.webpageText?.slice(secondWebpageTextSplit)}
                  </span>
                </Box>
              )}
            </ScrollArea>
          </Tabs.Panel>
        </Tabs>
      )}
    </Paper>
  );
};

export default WebpagePreview;
